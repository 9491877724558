import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { getDeadById, updateDead } from '../services/api';

const DeadForm = ({ id, onClose, onUpdate }) => {
  const [dead, setDead] = useState({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchDead = async () => {
      try {
        const response = await getDeadById(id);
        setDead(response.data);
      } catch (err) {
        console.error('Błąd pobierania danych', err);
        setError('Nie udało się pobrać danych zmarłego.');
      }
    };

    fetchDead();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDead(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    if (!dead.gender) {
      setError('Proszę wybrać płeć.');
      return false;
    }
    if (!dead.graveno || !dead.graveno.trim()) {
      setError('Proszę podać numer grobu.');
      return false;
    }
    if (dead.bornyear && parseInt(dead.bornyear) > currentYear) {
      setError(`Rok urodzenia nie może być późniejszy niż ${currentYear}.`);
      return false;
    }
    if (dead.deathyear && parseInt(dead.deathyear) > currentYear) {
      setError(`Rok śmierci nie może być późniejszy niż ${currentYear}.`);
      return false;
    }
    if (dead.bornyear && dead.deathyear && parseInt(dead.bornyear) > parseInt(dead.deathyear)) {
      setError('Rok urodzenia nie może być późniejszy niż rok śmierci.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (!validateForm()) {
      return;
    }
    try {
      await updateDead(id, dead);
      setSuccess('Dane zostały zaktualizowane.');
      if (onUpdate) onUpdate();
    } catch (err) {
      console.error('Błąd aktualizacji danych', err);
      setError('Nie udało się zaktualizować danych.');
    }
  };

  return (
    <Form className="bg-light" onSubmit={handleSubmit}>
      <Row>
        <Col md={2}>
          <Form.Group className="mb-3">
            <Form.Label>Tytuł</Form.Label>
            <Form.Control type="text" name="title" value={dead.title || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group className="mb-3">
            <Form.Label>Imię</Form.Label>
            <Form.Control type="text" name="name" value={dead.name || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group className="mb-3">
            <Form.Label>Nazwisko</Form.Label>
            <Form.Control type="text" name="surname" value={dead.surname || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Płeć *</Form.Label>
            <Form.Select name="gender" value={dead.gender || ''} onChange={handleChange} required>
              <option value="">Wybierz płeć</option>
              <option value="m">Mężczyzna</option>
              <option value="f">Kobieta</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Wiek</Form.Label>
            <Form.Control type="number" name="age" value={dead.age || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Jednostka wieku</Form.Label>
            <Form.Select name="ageunit" value={dead.ageunit || ''} onChange={handleChange}>
              <option value="years">lat</option>
              <option value="months">miesięcy</option>
              <option value="days">dni</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Data urodzenia</Form.Label>
            <Form.Control type="date" name="borndate" value={dead.borndate || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Rok urodzenia</Form.Label>
            <Form.Control
              type="number"
              name="bornyear"
              value={dead.bornyear || ''}
              onChange={handleChange}
              max={currentYear}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Data śmierci</Form.Label>
            <Form.Control type="date" name="deathdate" value={dead.deathdate || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Rok śmierci</Form.Label>
            <Form.Control
              type="number"
              name="deathyear"
              value={dead.deathyear || ''}
              onChange={handleChange}
              max={currentYear}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-3">
        <Form.Label>Numer grobu *</Form.Label>
        <Form.Control type="text" name="graveno" value={dead.graveno || ''} onChange={handleChange} required />
      </Form.Group>

      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Informacje</Form.Label>
            <Form.Control as="textarea" rows={3} name="info" value={dead.info || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Uwagi</Form.Label>
            <Form.Control as="textarea" rows={3} name="remarks" value={dead.remarks || ''} onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" type="submit">Zapisz zmiany</Button>
      <Button variant="secondary" onClick={onClose} className="ms-2">Anuluj</Button>
    </Form>
  );
};

export default DeadForm;
