import React from 'react';
import { Nav } from 'react-bootstrap';
import { logout } from '../services/api';

const Logout = ({ onLogout }) => {
  const handleLogout = async () => {
    try {
      await logout();
      onLogout();
    } catch (err) {
      console.error('Błąd wylogowania', err);
    }
  };

  return (
    <Nav.Link onClick={handleLogout}>Wyloguj</Nav.Link>
  );
};

export default Logout;
