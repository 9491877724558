import axios from 'axios';

const API_URL = 'https://admin.cmentarzpomiechowo.pl/api';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

// Dodajemy interceptor do dodawania tokenu do każdego żądania
/*
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
*/
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  console.log('Token przed wysłaniem żądania:', token);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

/*
export const login = async (email, password) => {
  const response = await api.post('/login', { email, password });
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
  }
  return response;
};
*/
export const login = async (email, password) => {
  try {
    const response = await api.post('/login', { email, password });
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      console.log('Token zapisany w localStorage:', response.data.token);
    } else {
      console.error('Brak tokenu w odpowiedzi serwera');
    }
    return response;
  } catch (error) {
    console.error('Błąd logowania:', error);
    throw error;
  }
};

export const logout = async () => {
  const response = await api.post('/logout');
  localStorage.removeItem('token');
  return response;
};

export const checkAuth = async () => {
  const token = localStorage.getItem('token');
  console.log('Token z localStorage:', token); // Dodajemy log
  if (!token) {
    throw new Error('Brak tokenu');
  }
  return api.get('/check-auth');
};

//export const checkAuth = () => api.get('/check-auth');

//export const login = (email, password) => api.post('/login', { email, password });
//export const logout = () => api.post('/logout');
export const search = (query) => api.post('/search', { query });
export const searchComplex = (query) => api.post('/search_complex', { query });
export const getDeadById = (id) => api.get(`/dead?id=${id}`);
export const updateDead = (id, data) => api.put('/dead', { deadid: id, ...data });
export const addDead = (data) => api.post('/dead', data); // Nowa funkcja


export default api;
