import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Form, Button, Alert, ProgressBar, Image } from 'react-bootstrap';
import axios from 'axios';

const GravePhotoUpload = () => {
  const [graveNumber, setGraveNumber] = useState('');
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [progress, setProgress] = useState(0);
  const [previews, setPreviews] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true
  });

  useEffect(() => {
    if (!files.length) {
      setPreviews([]);
      return;
    }

    const objectUrls = files.map(file => URL.createObjectURL(file));
    setPreviews(objectUrls);

    // Cleanup function to revoke the object URLs
    return () => objectUrls.forEach(url => URL.revokeObjectURL(url));
  }, [files]);

  const validateGraveNumber = (number) => {
    const regex = /^[0-9A-F]{3}-[0-9A-F]{3}$/;
    return regex.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setProgress(0);

    if (!validateGraveNumber(graveNumber)) {
      setError('Nieprawidłowy numer grobu. Format powinien być XXX-XXX, gdzie X to cyfra od 0 do 9 lub litera od A do F.');
      return;
    }

    if (files.length === 0) {
      setError('Proszę wybrać co najmniej jedno zdjęcie.');
      return;
    }

    const formData = new FormData();
    formData.append('graveNumber', graveNumber);
    files.forEach((file, index) => {
      formData.append(`photo${index}`, file);
    });

    try {
      await axios.post('/api/upload_grave_photos.php', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });
      setSuccess('Zdjęcia zostały pomyślnie przesłane.');
      setFiles([]);
      setGraveNumber('');
      setProgress(0);
    } catch (err) {
      console.error('Błąd podczas przesyłania zdjęć', err);
      setError('Wystąpił błąd podczas przesyłania zdjęć.');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Numer grobu</Form.Label>
        <Form.Control
          type="text"
          value={graveNumber}
          onChange={(e) => setGraveNumber(e.target.value.toUpperCase())}
          placeholder="np. 123-ABC"
          required
        />
      </Form.Group>

      <div {...getRootProps()} className="dropzone mb-3 p-3 border rounded">
        <input {...getInputProps()} />
        <div className="text-center mb-3">
          {isDragActive ? (
            <p>Upuść zdjęcia tutaj ...</p>
          ) : (
            <p>Przeciągnij i upuść zdjęcia tutaj, lub kliknij aby wybrać pliki</p>
          )}
        </div>
        
        {previews.length > 0 && (
          <div>
            <h5 className="mb-2">Podgląd zdjęć:</h5>
            <div className="d-flex flex-wrap justify-content-start">
              {previews.map((preview, index) => (
                <Image
                  key={index}
                  src={preview}
                  alt={`Preview ${index + 1}`}
                  thumbnail
                  className="me-2 mb-2"
                  style={{width: '80px', height: '80px', objectFit: 'cover'}}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {progress > 0 && progress < 100 && (
        <ProgressBar className="mb-3" now={progress} label={`${progress}%`} />
      )}

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Button variant="primary" type="submit">Prześlij zdjęcia</Button>
    </Form>
  );
};

export default GravePhotoUpload;
