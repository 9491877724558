import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';
import Login from './components/Login';
import Logout from './components/Logout';
import Search from './components/Search';
import SearchResults from './components/SearchResults';
import AddDead from './components/AddDead';
import GravePhotoUpload from './components/GravePhotoUpload';
import { searchComplex, checkAuth } from './services/api';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [activeTab, setActiveTab] = useState('search');
  const [lastSearchQuery, setLastSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        await checkAuth();
        setIsLoggedIn(true);
        setActiveTab('search');
      } catch (error) {
        console.error('Błąd autoryzacji:', error);
        setIsLoggedIn(false);
        setActiveTab('login');
      } finally {
        setIsLoading(false);
      }
    };

    verifyAuth();
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    setActiveTab('search');
    clearSearch();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setActiveTab('login');
    clearSearch();
  };

  const handleSearch = (results, query) => {
    setSearchResults(results);
    setLastSearchQuery(query);
  };

  const handleUpdate = async () => {
    if (lastSearchQuery) {
      try {
        const response = await searchComplex(lastSearchQuery);
        setSearchResults(response.data);
      } catch (error) {
        console.error('Błąd podczas odświeżania wyników:', error);
      }
    }
  };

  const clearSearch = () => {
    setSearchResults([]);
    setLastSearchQuery('');
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'search') {
      clearSearch();
    }
  };

  const handleEditComplete = () => {
    clearSearch();
  };

  if (isLoading) {
    return <div>Ładowanie...</div>;
  }

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand>Cmentarz Pomiechowo</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {isLoggedIn && (
                <>
                  <Nav.Link
                    onClick={() => handleTabChange('search')}
                    active={activeTab === 'search'}
                  >
                    Wyszukiwanie
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleTabChange('addDead')}
                    active={activeTab === 'addDead'}
                  >
                    Dodaj zmarłego
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleTabChange('uploadPhoto')}
                    active={activeTab === 'uploadPhoto'}
                  >
                    Dodaj zdjęcia
                  </Nav.Link>
                </>
              )}
            </Nav>
            <Nav>
              {isLoggedIn ? (
                <Logout onLogout={handleLogout} />
              ) : (
                <Nav.Link
                  onClick={() => handleTabChange('login')}
                  active={activeTab === 'login'}
                >
                  Logowanie
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="mt-4">
        {!isLoggedIn && activeTab === 'login' && (
          <Login onLogin={handleLogin} />
        )}
        {isLoggedIn && activeTab === 'search' && (
          <>
            <Search onSearchResults={handleSearch} />
            <SearchResults
              results={searchResults}
              onUpdate={handleUpdate}
              onEditComplete={handleEditComplete}
            />
          </>
        )}
        {isLoggedIn && activeTab === 'addDead' && (
          <AddDead onAdd={() => {
            handleUpdate();
            handleTabChange('search');
          }} />
        )}
        {isLoggedIn && activeTab === 'uploadPhoto' && (
          <GravePhotoUpload />
        )}
      </Container>
    </>
  );
}

export default App;
