import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { searchComplex } from '../services/api';

const Search = ({ onSearchResults, activeTab }) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(''); // Resetujemy pole wyszukiwania przy zmianie zakładki
  }, [activeTab]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await searchComplex(query);
      onSearchResults(response.data, query);
    } catch (err) {
      console.error('Błąd wyszukiwania', err);
      onSearchResults([], '');
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-4">
      <Form.Group className="mb-3">
        <Form.Label>Wyszukiwanie</Form.Label>
        <Form.Control
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Wpisz imię i/lub nazwisko..."
          required
        />
        <Form.Text className="text-muted">
          Wpisz nazwisko lub imię i nazwisko, aby wyszukać osobę.
        </Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit">Szukaj</Button>
    </Form>
  );
};

export default Search;
