import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { getDeadById } from '../services/api';

const DeadDetails = ({ id, onClose }) => {
  const [dead, setDead] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDead = async () => {
      try {
        const response = await getDeadById(id);
        setDead(response.data);
      } catch (err) {
        console.error('Błąd pobierania danych', err);
        setError('Nie udało się pobrać danych zmarłego.');
      }
    };

    fetchDead();
  }, [id]);

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  if (!dead) {
    return <div>Ładowanie...</div>;
  }

  const graveNo = dead.graveno || 'Brak danych';
  const url = graveNo !== 'Brak danych' ? `https://www.cmentarzpomiechowo.pl/pub/l.php?id=${graveNo}` : '#';


  return (
    <Card>
      <Card.Body>
        <Card.Title>{dead.name} {dead.surname}</Card.Title>
        <Card.Text>
          <strong>Data urodzenia:</strong> {dead.borndate || 'Brak danych'}<br/>
          <strong>Data śmierci:</strong> {dead.deathdate || 'Brak danych'}<br/>
          <strong>Rok urodzenia:</strong> {dead.bornyear || 'Brak danych'}<br/>
          <strong>Rok śmierci:</strong> {dead.deathyear || 'Brak danych'}<br/>
          <strong>Wiek:</strong> {dead.age ? `${dead.age} ${dead.ageunit}` : 'Brak danych'}<br/>
          <strong>Informacje:</strong> {dead.info || 'Brak danych'}<br/>
          <strong>Uwagi:</strong> {dead.remarks || 'Brak danych'}<br/>
	  <strong>Grób:</strong> {graveNo !== 'Brak danych' ? <a target="_blank" href={url}>{graveNo}</a> : graveNo}
        </Card.Text>
        <Button variant="secondary" onClick={onClose}>Zamknij</Button>
      </Card.Body>
    </Card>
  );
};

export default DeadDetails;
