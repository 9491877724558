import React, { useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import DeadDetails from './DeadDetails';
import DeadForm from './DeadForm';

const SearchResults = ({ results, onUpdate, onEditComplete }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  if (!results.length) return <p className="mt-4">Brak wyników.</p>;

  const handleShowDetails = (id) => {
    setSelectedId(id);
    setShowDetails(true);
  };

  const handleShowEditForm = (id) => {
    setSelectedId(id);
    setShowEditForm(true);
  };

  const handleCloseModal = () => {
    setShowDetails(false);
    setShowEditForm(false);
    setSelectedId(null);
  };

  const handleUpdate = () => {
    if (onUpdate) onUpdate();
    handleCloseModal();
  };

  const handleEditComplete = () => {
    handleCloseModal();
    if (onEditComplete) onEditComplete();
  };

  return (
    <div className="mt-4">
      <h3>Wyniki wyszukiwania</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Imię</th>
            <th>Nazwisko</th>
            <th>Data śmierci</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {results.map((person) => (
            <tr key={person.deadid}>
              <td>{person.name}</td>
              <td>{person.surname}</td>
              <td>{person.deathdate}</td>
              <td>
                <Button variant="info" size="sm" onClick={() => handleShowDetails(person.deadid)} className="me-2">
                  Szczegóły
                </Button>
                <Button variant="warning" size="sm" onClick={() => handleShowEditForm(person.deadid)}>
                  Edytuj
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal className="modal-xl" show={showDetails || showEditForm} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{showDetails ? 'Szczegóły' : 'Edycja danych'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDetails && selectedId && <DeadDetails id={selectedId} onClose={handleCloseModal} />}
          {showEditForm && selectedId && (
            <DeadForm
              id={selectedId}
              onClose={handleCloseModal}
              onUpdate={handleEditComplete}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SearchResults;
